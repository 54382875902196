import React from "react"
import PropTypes from "prop-types"

import BlogPostExcerpt from "./post/BlogPostExcerpt"

const BlogPostsList = (props) => {
  const { posts } = props

  return (
    <div>
      {posts.map(p => (
        <BlogPostExcerpt
          key={p.node.slug}
          excerptData={{
            headerData: {
              metadata: {
                dateString: p.node.publishedDate,
                minutesRead: p.node.content.childMarkdownRemark.timeToRead,
                category: p.node.category,
              },
              title: p.node.title,
              tags: p.node.tags,
            },
            fragmentData: {
              summary: p.node.content.childMarkdownRemark.excerpt,
              slug: p.node.slug,
            },
            imgUrl: p.node.image.fluid,
          }}
        />
      ))}
    </div>
  )
}

BlogPostsList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
}

export default BlogPostsList