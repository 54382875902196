export const white = "#ffffff"

export const lightGrey = "#dfdfdf"
export const grey = "#a3a3a3"
export const darkGrey = "#443c3c"
export const greyShadow = "#cecece"
export const greyNavBackground = "#fdfcff"

export const heliotrope = "#8c6dff"
export const heliotropeDark = "#684dcd"
export const heliotropeLayer = "rgba(140, 109, 255, 0.7)"

export const shamrock = "#2ec8ae"
export const shamrockDark = "#1aaf96"

export const almostBlack = "#313131"
export const black = "#000000"