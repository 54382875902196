import styled from "styled-components"

import * as Colors from "../../atomics/colors"

const BlogPostContent = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 16px;
  color: ${Colors.darkGrey};
  line-height: 1.88;
`

export default BlogPostContent