import styled from "styled-components"

import * as Colors from "../../atomics/colors"

const PostImageLayer = styled.div`
  position: absolute;
  background-color: ${Colors.heliotropeLayer};
  visibility: hidden;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;

  > span {
    font-family: "Ubuntu", sans-serif;
    color: ${Colors.white};
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    margin-right: 8px
  }
`

export default PostImageLayer