import * as React from "react"
import styled from "styled-components"

import { resizableContainerStyles } from "../../styles/containers"
import Aside from "./Aside"

const ContentContainer = styled.main`
  ${resizableContainerStyles};

  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  margin: 48px auto 0;
  padding: 0 12px;
  height: 100%;

  @media only screen and (min-width: 690px) {
    margin-top: 48px;
  }

  @media only screen and (max-width: 690px) {
    margin-top: 16px;
  }

  @media only screen and (min-width: 800px) {
    flex-direction: row;
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`

const MainContentSection = styled.section`
  flex: 1 0 0;
  
  @media only screen and (max-width: 800px) {
    width: 100%;
  }

  @media only screen and (min-width: 800px) {
    width: calc(100% - 320px);
  }
`

const Content = (props) => {
  const { children } = props

  return (
    <ContentContainer>
      <MainContentSection className="main-content">{children}</MainContentSection>
      <Aside />
    </ContentContainer>
  )
}

export default Content