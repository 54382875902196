import styled from "styled-components"

import * as Colors from "../../atomics/colors"
import PostImageLayer from "./PostImageLayer"

const PostImageContainer = styled.div`
  @media only screen and (max-width: 480px) {
    max-height: 107px;
  }

  @media only screen and (min-width: 480px) {
    max-height: 225px;
  }

  > .gatsby-image-wrapper {
    @media only screen and (max-width: 480px) {
      max-height: 105px;
    }
  
    @media only screen and (min-width: 480px) {
      max-height: 223px;
    }
  }

  width: 100%;
  border: 1px solid ${Colors.heliotrope};
  border-radius: 4px;
  position: relative;

  :hover {
    > ${PostImageLayer} {
      visibility: visible;
    }
  }
`

export default PostImageContainer