import _ from "lodash"
import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import Tag, { TagContainer } from "../../atomics/Tag"

const BlogPostTagsContainer = styled.div`
  margin-top: 8px;

  > ${TagContainer}:not(:first-of-type) {
    margin-left: 8px;
  }
`

const BlogPostTags = (props) => {
  const { tagsList } = props

  return (
    <BlogPostTagsContainer className="post-tags">
      {_.orderBy(tagsList, ["name"], ["asc"]).map(t => {
        return (
          <Tag key={t.slug} tagName={t.name} slug={t.slug} />
        )
      })}
    </BlogPostTagsContainer>
  )
}

BlogPostTags.propTypes = {
  tagsList: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default BlogPostTags