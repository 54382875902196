import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const SidePanelContentWrapper = styled.div`
  padding: 16px 16px 24px;
  font-size: 14px;
  line-height: 1.5;

  > h3 {
    font-family: "Open Sans", sans-serif;
    margin-bottom: 16px;
    font-size: 18px;
  }
`

const SidePanelContent = (props) => {
  const { title, children } = props

  return (
    <SidePanelContentWrapper className="side-panel-content">
      <h3 className="side-panel-title">{title}</h3>
      <div>
        {children}
      </div>
    </SidePanelContentWrapper>
  )
}

SidePanelContent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default SidePanelContent