import React from "react"
import PropTypes from "prop-types"

import ArrowLeftIcon from "../../atomics/assets/lnr-arrow-left-heliotrope.svg"
import IconButton from "./IconButton"

const ArrowLeftButton = (props) => {
  const { onClick, className } = props

  return (
    <IconButton onClick={onClick} className={className} alignment="left">
      <img src={ArrowLeftIcon} alt="Previous categories" />
    </IconButton>
  )
}

ArrowLeftButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default ArrowLeftButton