import styled from "styled-components"
import PropTypes from "prop-types"

import * as Colors from "../../atomics/colors"

const VerticalLine = styled.div`
  height: 27px;
  width: 1px;
  background-color: ${Colors.lightGrey};
  margin: 0 8px;
  height: ${props => props.cssHeight}px;
`

VerticalLine.propTypes = {
  cssHeight: PropTypes.number.isRequired,
}

export default VerticalLine