import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import VerticalLine from "../header/VerticalLine"
import GithubLogo from "../../images/icon-github.svg"
// import FacebookLogo from "../../images/icon-facebook.svg"
import TwitterLogo from "../../images/icon-twitter.svg"
import Link from "../../atomics/Link"

const ActionsBar = styled.div`
  display: flex;
  align-items: center;
  max-width: 220px;
  justify-content: flex-end;

  ${props => props.hideable
    ? `@media only screen and (max-width: 480px) {
          display: none;
        }`
    : ""}
`

ActionsBar.propTypes = {
  hideable: PropTypes.bool,
}

const SocialMedia = styled.div`
  display: flex;
  align-items: center;

  > a {
    user-select: none;
    margin-top: 4px;
    
    :not(:first-of-type) {
      margin-left: 16px;
    }
  }
`

const RightPanel = (props) => {
  const { hideable } = props

  return (
    <ActionsBar className="header-links" hideable={hideable}>
      <SocialMedia className="social-media">
        <a href="https://github.com/tripleequalsdev" rel="external">
          <img src={GithubLogo} alt="Github" />
        </a>
        {/* <a href="https://www.facebook.com" rel="external">
          <img src={FacebookLogo} alt="Facebook" />
        </a> */}
        <a href="https://twitter.com/tripleequalsdev" rel="external">
          <img src={TwitterLogo} alt="Twitter" />
        </a>
      </SocialMedia>
    </ActionsBar>
  )
}

RightPanel.propTypes = {
  hideable: PropTypes.bool,
}

export default RightPanel