import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"

import * as Colors from "../../atomics/colors"
import BlogPostContainer from "./BlogPostContainer"
import BlogPostHeader from "./BlogPostHeader"
import BlogPostFragment from "./BlogPostFragment"
import ArrowRight from "../../atomics/assets/lnr-arrow-right.svg"
import PostImageContainer from "./PostImageContainer"
import PostImageLayer from "./PostImageLayer"
import Link from "../../atomics/Link"

const GotoImage = styled.img`
  width: 16px;
  height: 11px;
`

const BlogPostDivider = styled.hr`
  height: 1px;
  background-color: ${Colors.lightGrey};
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 24px;
  }
`

const BlogPostExcerpt = (props) => {
  const { excerptData: { headerData, fragmentData, imgUrl } } = props

  return (
    <BlogPostContainer variant="post-excerpt">
      <BlogPostHeader data={headerData} slug={fragmentData.slug} />
      <Link
        to={`/posts/${fragmentData.slug}`}
        className="featured-image"
        label={headerData.title}
      >
        <PostImageContainer className="post-thumbnail">
          <Img fluid={imgUrl} imgStyle={{ objectFit: "cover" }} />
          <PostImageLayer>
            <span>Go to the post</span>
            <GotoImage className="arrowright-icon" src={ArrowRight} alt="Right Arrow Icon" />
          </PostImageLayer>
        </PostImageContainer>
      </Link>
      <BlogPostFragment data={fragmentData} />
      <BlogPostDivider className="post-divider" />
    </BlogPostContainer>
  )
}

BlogPostExcerpt.propTypes = {
  excerptData: PropTypes.object.isRequired,
}

export default BlogPostExcerpt