import React from "react"
import PropTypes from "prop-types"

import ArrowRightIcon from "../../atomics/assets/lnr-arrow-right-heliotrope.svg"
import IconButton from "./IconButton"

const ArrowRightButton = (props) => {
  const { onClick, className } = props

  return (
    <IconButton onClick={onClick} className={className} alignment="right">
      <img src={ArrowRightIcon} alt="Next categories" />
    </IconButton>
  )
}

ArrowRightButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default ArrowRightButton