import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const BlogPostWrapper = styled.article`
  margin-bottom: 32px;
`

const BlogPostContainer = (props) => {
  const { variant, children } = props

  return (
    <BlogPostWrapper className={props.variant}>
      {children}
    </BlogPostWrapper>
  )
}

BlogPostContainer.propTypes = {
  variant: PropTypes.oneOf(["post-excerpt", "post-full"]).isRequired,
}

export default BlogPostContainer