import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import * as Colors from "../../atomics/colors"

const IconButtonWrapper = styled.div`
  display: flex;
  width: 35px;
  height: 32px;
  background-color: ${Colors.greyNavBackground};
  justify-content: center;
  align-items: center;
  position: absolute;
  ${props => props.alignment}: 0;
`

const IconButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 17px;
  box-shadow: 2px 2px 5px 0 rgba(68, 60, 60, 0.1);
  background-color: ${Colors.white};
  cursor: pointer;
  outline: none;
  border: none;
`

const IconButton = (props) => {
  const { alignment, onClick, children, className } = props

  return (
    <IconButtonWrapper
      alignment={alignment}
    >
      <IconButtonContainer
        onClick={onClick}
        role="button"
        tabIndex="0"
        className={className}
      >
        {children}
      </IconButtonContainer>
    </IconButtonWrapper>
  )
}

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  alignment: PropTypes.oneOf(["left", "right"]).isRequired,
}

export default IconButton