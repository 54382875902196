import axios from "axios"
import { Index } from "elasticlunr"

const getPage = (index, path) => {
  const uriSubpath = path || "root"
  return axios.get(`/lazyPages/${uriSubpath}/${index}.json`)
    .then((res) => {
      return res.data
    })
}

const search = (query) => {
  return axios.get("/searchIndex/search-index.json")
    .then((res) => {
      const index = Index.load(res.data)
      return index.search(query).map(({ ref }) => index.documentStore.getDoc(ref))
    })
}

export {
  getPage,
  search,
}