import { css } from "styled-components"

export const resizableContainerStyles = css`
  @media only screen and (max-width: 690px) {
    width: 100%;
  }

  @media only screen and (min-width: 690px) and (max-width: 800px) {
    width: 650px;
  }

  @media only screen and (min-width: 800px) and (max-width: 1024px) {
    width: 740px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1224px) {
    width: 920px;
  }

  @media only screen and (min-width: 1224px) {
    width: 1160px;
  }
`