import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import * as Colors from "../../atomics/colors"
import HeaderLeftPanel from "./HeaderLeftPanel"
import RightPanel from "../common/RightPanel"
import { resizableContainerStyles } from "../../styles/containers"

const HeaderWrapper = styled.header`
  background-color: ${Colors.white};
  height: 58px;
  border-bottom: 1px solid ${Colors.lightGrey};
`

const HeaderContent = styled.div`
  ${resizableContainerStyles};

  display: flex;
  margin: 0 auto;
  padding: 0 12px;
  height: 100%;
  justify-content: space-between;
`

const Header = ({ siteTitle }) => (
  <HeaderWrapper>
    <HeaderContent>
      <HeaderLeftPanel />
      <RightPanel hideable />
    </HeaderContent>
  </HeaderWrapper>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: "",
}

export default Header
