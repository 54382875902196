import React from "react"
import styled from "styled-components"
import smoothscroll from "smoothscroll-polyfill"
import _ from "lodash"
import { useStaticQuery, graphql } from "gatsby"

import * as Colors from "../../atomics/colors"
import Link from "../../atomics/Link"
import { resizableContainerStyles } from "../../styles/containers"
import ArrowRightButton from "./ArrowRightButton"
import ArrowLeftButton from "./ArrowLeftButton"
import SearchBar from "./SearchBar"

const NavbarWrapper = styled.div`
  background-color: ${Colors.greyNavBackground};
`

const NavbarContainer = styled.div`
  ${resizableContainerStyles};

  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 12px;
  height: 100%;

  @media only screen and (min-width: 690px) {
    flex-direction: row;
  }

  @media only screen and (max-width: 690px) {
    flex-direction: column;
  }
`

const NavbarContent = styled.nav`
  height: 43px;
  position: relative;
  display: flex;
  flex: 1 0 43px;
  overflow-x: hidden;
  align-items: center;
`

const NavbarList = styled.ul`
  list-style: inside none;
  display: flex;
  height: 100%;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  flex-grow: 0;
  margin: 0;
`

const CategoryEntry = styled.li`
  white-space: nowrap;

  :not(:first-of-type) {
    margin-left: 24px;
  }
`

const Navbar = () => {
  const { allContentfulCategory } = useStaticQuery(graphql`
    query {
      allContentfulCategory(sort: { fields: [priority], order: DESC }) {
        totalCount
        edges {
          node {
            slug
            name
          }
        }
      }
    }
  `)

  const { totalCount, edges } = allContentfulCategory

  const [overflowing, setOverflowing] = React.useState(null)
  const [scrollPositionHistory, setScrollPositionHistory] = React.useState([0])

  const navbarRef = React.useRef(null)
  const navbarListRef = React.useRef(null)

  const categoryEntriesRefs = React.useMemo(() =>
    Array.from(
      { length: totalCount + 1 },
      () => React.createRef(),
    ), [])

  const detectIfOverflows = () => {
    const navbarContainerRightEdge = navbarRef.current.getBoundingClientRect().right
    const overflowingIndex = categoryEntriesRefs
      .findIndex(cer => cer.current.getBoundingClientRect().right > navbarContainerRightEdge + 5)

    setOverflowing(
      overflowingIndex === -1
        ? null
        : categoryEntriesRefs[overflowingIndex].current,
    )
  }

  const _resizeHandler = () => {
    detectIfOverflows()
    navbarListRef.current.scrollTo({
      left: 0,
    })
  }

  const resizeHandler = _.debounce(_resizeHandler, 100)

  React.useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  React.useEffect(() => {
    window.addEventListener("resize", resizeHandler)
    return () => {
      window.removeEventListener("resize", resizeHandler)
    }
  }, [])

  React.useEffect(() => {
    detectIfOverflows()
  }, [scrollPositionHistory])

  const isOverflowing = React.useMemo(() => {
    return overflowing !== null
  }, [overflowing])

  const onShowNextClick = () => {
    const historyEntriesNo = scrollPositionHistory.length
    navbarListRef.current.scrollTo({
      left: scrollPositionHistory[historyEntriesNo - 1] + overflowing.getBoundingClientRect().left - 35,
      behavior: "smooth",
    })
  }

  const onShowPreviousClick = () => {
    navbarListRef.current.scrollTo({
      left: scrollPositionHistory[scrollPositionHistory.length - 2],
      behavior: "smooth",
    })
  }

  const _onNavbarScroll = () => {
    const historyEntriesNo = scrollPositionHistory.length
    if (navbarListRef.current.scrollLeft === 0) {
      setScrollPositionHistory([0])
    } else if (navbarListRef.current.scrollLeft < scrollPositionHistory[historyEntriesNo - 1]) {
      setScrollPositionHistory(
        scrollPositionHistory.slice(0, historyEntriesNo - 1),
      )
    } else {
      setScrollPositionHistory([
        ...scrollPositionHistory,
        navbarListRef.current.scrollLeft,
      ])
    }
  }

  const onNavbarScroll = _.debounce(_onNavbarScroll, 50)

  return (
    <NavbarWrapper>
      <NavbarContainer>
        <NavbarContent aria-label="Posts categories" ref={navbarRef}>
          <NavbarList className="categories" onScroll={onNavbarScroll} ref={navbarListRef}>
            <CategoryEntry ref={categoryEntriesRefs[0]} className="category"><Link to="/">All</Link></CategoryEntry>
            {edges.map((e, i) => {
              return (
                <CategoryEntry
                  ref={categoryEntriesRefs[i + 1]}
                  className="category"
                  key={e.node.slug}
                >
                  <Link to={`/categories/${e.node.slug}`}>{e.node.name}</Link>
                </CategoryEntry>
              )
            })}
          </NavbarList>
          {scrollPositionHistory.length > 1 && (
            <ArrowLeftButton className="prev-categories-btn" onClick={onShowPreviousClick}>-></ArrowLeftButton>
          )}
          {isOverflowing && (
            <ArrowRightButton className="next-categories-btn" onClick={onShowNextClick}>-></ArrowRightButton>
          )}
        </NavbarContent>
        <SearchBar />
      </NavbarContainer>
    </NavbarWrapper>
  )
}

export default Navbar