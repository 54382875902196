import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import Link from "../../atomics/Link"
import * as Colors from "../../atomics/colors"

const BlogPostMetadataContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.grey};
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
  flex-wrap: wrap;
`

const BlogPostMetadataSeparator = styled.div`
  background-color: ${Colors.grey};
  width: 4px;
  height: 4px;
  border-radius: 5px;
  margin: 0 8px;
`

const CategoryLink = styled.span`
  color: ${Colors.grey};

  :hover > a {
    color: ${Colors.heliotrope};
  }
`

const BlogPostMetadata = (props) => {
  const { dateString, minutesRead: mr, category } = props
  const minutesFormatted = `${mr} ${mr > 1 ? "minutes" : "minute"} read`

  return (
    <BlogPostMetadataContainer className="post-metadata">
      <time>{dateString}</time>
      <BlogPostMetadataSeparator />
      <span>{minutesFormatted}</span>
      <BlogPostMetadataSeparator />
      <CategoryLink>
        <Link to={`/categories/${category.slug}`}>
          {category.name}
        </Link>
      </CategoryLink>
    </BlogPostMetadataContainer>
  )
}

BlogPostMetadata.propTypes = {
  dateString: PropTypes.string.isRequired,
  minutesRead: PropTypes.number.isRequired,
  category: PropTypes.object.isRequired,
}

export default BlogPostMetadata