import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import BlogPostMetadata from "./BlogPostMetadata"
import PostTitle from "../../atomics/PostTitle"
import BlogPostTags from "./BlogPostTags"

const BlogPostHeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`

const BlogPostHeader = (props) => {
  const { data: { metadata, title, tags }, slug } = props

  return (
    <BlogPostHeaderContainer>
      <BlogPostMetadata
        {...metadata}
      />
      <PostTitle slug={slug}>{title}</PostTitle>
      <BlogPostTags tagsList={tags} />
    </BlogPostHeaderContainer>
  )
}

BlogPostHeader.propTypes = {
  data: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
}

export default BlogPostHeader