import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import BlogPostContent from "./BlogPostContent"
import * as Colors from "../../atomics/colors"
import Link from "../../atomics/Link"

const ReadMoreLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  color: ${Colors.heliotrope};
  padding-left: 8px;
`

const BlogPostFragment = (props) => {
  const { data: { summary, slug } } = props

  return (
    <BlogPostContent>
      <p>
        <span>
          {summary}&nbsp;
          <ReadMoreLink to={`/posts/${slug}`}>Read more</ReadMoreLink>
        </span>
      </p>
    </BlogPostContent>
  )
}

BlogPostFragment.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BlogPostFragment