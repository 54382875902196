import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

import * as Colors from "./colors"

const PostTitleContainer = styled.h1`
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.33;
  color: ${Colors.darkGrey};
  display: inline-block;
  margin: 0;

  :hover {
    a {
      color: ${Colors.heliotrope};
    }
  }

  a {
    text-decoration: none;
    color: ${Colors.darkGrey};
  }
`

const PostTitle = (props) => {
  return (
    <PostTitleContainer className="post-title">
      <Link
        to={`/posts/${props.slug}`}
        rel="bookmark"
      >
        {props.children}
      </Link>
    </PostTitleContainer>
  )
}

PostTitle.propTypes = {
  children: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}

export default PostTitle