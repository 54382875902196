import styled from "styled-components"
import PropTypes from "prop-types"

import * as Colors from "../../atomics/colors"

const SidePanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  box-shadow: 2px 10px 30px 0 rgba(68, 60, 60, 0.1);
  background-color: ${props => props.variant === "primary" ? "white" : Colors.heliotrope};
  color: ${props => props.variant === "primary" ? Colors.darkGrey : "white"};
`

SidePanelWrapper.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary"]).isRequired,
}

export default SidePanelWrapper