import React from "react"
import styled from "styled-components"

import Search from "../../atomics/Search"
import * as Colors from "../../atomics/colors"

const SearchBarContainer = styled.div`
  display: flex;
  height: 43px;
  align-items: center;
  flex: 1 0 43px;
  background-color: ${Colors.greyNavBackground};

  @media only screen and (min-width: 690px) {
    margin-left: 100px;
    max-width: 220px;
  }

  @media only screen and (max-width: 690px) {
    margin-left: 0;
    width: 100%;
  }

  > form {
    width: 220px;

    > input {
      width: 100%;
    }
  }
`

const SearchBar = () => {
  return (
    // TODO: ARIA!
    <SearchBarContainer>
      <Search />
    </SearchBarContainer>
  )
}

export default SearchBar