import React from "react"
import PropTypes from "prop-types"

import AuthorImage from "./AuthorImage"
import SidePanelContent from "./SidePanelContent"
import SidePanelWrapper from "./SidePanelWrapper"

const SidePanel = (props) => {
  const { variant, children } = props

  return (
    <SidePanelWrapper
      variant={variant}
      className="side-panel"
    >
      <AuthorImage />
      <SidePanelContent title="Hi, I'm Karol">
        Experienced firmware engineer that decided to chase his dreams in a world full of closures, prototypes and triple equals. Fortunately, have ended up being happier and more satisfied than ever, and now can proudly call myself a true Javascript developer with some low-level deviations.
      </SidePanelContent>
    </SidePanelWrapper>
  )
}

SidePanel.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary"]).isRequired,
}

export default SidePanel