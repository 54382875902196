import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import VerticalLine from "./VerticalLine"
import * as Colors from "../../atomics/colors"
import LogoSmall from "../../images/logo-base-small.svg"

const PageBrand = styled.div`
  display: flex;
  flex: 1 0 0;
  align-items: center;

  > a {
    user-select: none;
    display: flex;
    padding-bottom: 3px;
    flex-shrink: 0;
  }
`

const PageSection = styled.div`
  display: flex;
  align-items: center;
  flex: 0;

  > h2 {
    color: ${Colors.almostBlack};
    font-size: 16px;
    display: inline-block;
    line-height: 22px;
    margin: 0;
  }

  @media (max-width: 480px) {
    display: none;
  }
`

const HeaderLeftPanel = () => {
  return (
    <PageBrand className="brand">
      <Link
        to="/"
      >
        <img src={LogoSmall} alt="Tripleequals" />
      </Link>
      <PageSection className="page-section" >
        <VerticalLine className="vertical-line" cssHeight={27} />
        <h2>Blog</h2>
      </PageSection>
    </PageBrand>
  )
}

export default HeaderLeftPanel