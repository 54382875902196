import styled from "styled-components"
import PropTypes from "prop-types"

import * as Colors from "./colors"

const Button = styled.button`
  display: inline-flex;
  border-radius: 2px;
  color: ${props => props.disabled
    ? Colors.grey
    : Colors.white};
  height: 34px;
  background-color: ${props => props.disabled
    ? Colors.lightGrey
    : props.variant === "primary"
      ? Colors.heliotrope
      : Colors.shamrock};
  box-shadow: 2px 2px 5px 0 ${Colors.greyShadow};
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 14px;
  padding: 10px 24px;
  outline: none;
  border: none;
  cursor: pointer;

  :hover, :active {
    background-color: ${props => props.disabled
      ? Colors.lightGrey
      : props.variant === "primary"
        ? Colors.heliotropeDark
        : Colors.shamrockDark
      };
  }
`

Button.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary"]).isRequired,
}

export default Button