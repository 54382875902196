import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import * as Colors from "./colors"
import { Link as GatsbyLink } from "gatsby"

export const LinkStyled = styled(GatsbyLink)`
  font-size: 14px;
  font-weight: 600;
  color: ${Colors.grey};
  text-decoration: none;

  &.active {
    color: ${Colors.heliotrope};
  }
`

const Link = (props) => {
  return (
    <LinkStyled
      activeClassName="active"
      to={props.to}
      rel="bookmark"
      className={props.className}
      aria-label={props.label}
    >
      {props.children}
    </LinkStyled>
  )
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
}

export default Link