import React from "react"

import { POSTS_PER_PAGE } from "../config"
import { GlobalDataContext } from "../provider/GlobalDataProvider"
import { getPage } from "../api/handlers"

const usePageFetch = (initialPosts, totals, path) => {
  const { posts, addPosts } = React.useContext(GlobalDataContext)

  const validPosts = posts[path] || initialPosts
  const [dataLoadInProgress, setDataLoadInProgress] = React.useState(false)

  React.useEffect(() => {
    if (posts[path] === undefined) {
      addPosts(path, [...initialPosts])
    }
  }, [posts, initialPosts])

  const currentPage = React.useMemo(() => {
    return Math.ceil(validPosts.length / POSTS_PER_PAGE)
  }, [validPosts])

  const onLoadClick = React.useCallback(async () => {
    const nextPage = currentPage + 1
    setDataLoadInProgress(true)
    try {
      const newData = await getPage(nextPage, path.slice(1))
      addPosts(path, newData)
    } catch (e) { }
    setDataLoadInProgress(false)
  }, [currentPage, posts])

  return {
    posts: validPosts,
    dataLoadInProgress,
    onLoadClick,
    totals,
    currentPage,
  }
}

export default usePageFetch