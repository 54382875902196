import React from "react"
import PropTypes from "prop-types"

import SEO from "../seo"
import Button from "../../atomics/Button"
import BlogPostsList from "../BlogPostsList"
import Spinner from "./Spinner"
import LoadMoreContainer from "./LoadMoreContainer"
import usePageFetch from "../../hooks/usePageFetch"

const BlogPostsListWithSeo = (props) => {
  const { initialPosts, totalCount, path, metaKeywords, metaTitle,
    metaDescription } = props

  const pageFetchData = usePageFetch(
    initialPosts,
    totalCount,
    path,
  )

  return (
    <>
      <SEO
        title={metaTitle}
        keywords={metaKeywords}
        description={metaDescription}
      />
      <BlogPostsList
        posts={pageFetchData.posts}
      />
      <LoadMoreContainer>
        {pageFetchData.dataLoadInProgress
          ? <Spinner />
          : (pageFetchData.posts.length < pageFetchData.totals) && (
            <Button
              variant="primary"
              onClick={pageFetchData.onLoadClick}
            >
              Load More Posts
            </Button>
          )
        }
      </LoadMoreContainer>
    </>
  )
}

BlogPostsListWithSeo.propTyps = {
  path: PropTypes.string.isRequired,
  initialPosts: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number.isRequired,
  metaKeywords: PropTypes.arrayOf(PropTypes.string),
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
}

export default BlogPostsListWithSeo