import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import * as Colors from "./colors"
import { Link } from "gatsby"

export const TagContainer = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  border: 1px solid ${Colors.lightGrey};
  border-radius: 2px;
  height: 22px;
  font-size: 12px;
  line-height: 10px;
  cursor: pointer;
  text-decoration: none;
  color: ${Colors.grey};

  :hover {
    color: ${Colors.darkGrey};
  }
`

const Tag = (props) => {
  const { tagName, slug } = props

  return (
    <TagContainer
      className="tag"
      rel="tag"
      to={`/tags/${slug}`}
    >
      #{tagName}
    </TagContainer>
  )
}

Tag.propTypes = {
  tagName: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Tag