import React from "react"
import styled from "styled-components"

import MagnifyingGlass from "./assets/icon-magnifier.svg"
import * as Colors from "./colors"

const InlineForm = styled.form`
  display: inline-block;
  position: relative;
  margin-bottom: 0;
`

const InputIcon = styled.img`
  position: absolute;
  top: 4px;
  left: 0;
  user-select: none;
  width: 16px;
  height: 16px;
`

const Input = styled.input`
  border: none;
  border-bottom: 1px solid ${Colors.grey};
  padding-left: 21px;
  font-size: 14px;
  color: ${Colors.darkGrey};
  padding-bottom: 3px;
  border-radius: 0;
  -webkit-appearance: none !important;
  background-color: transparent;

  ::placeholder {
    color: ${Colors.lightGrey};
  }

  :focus {
    outline: none;
    border-bottom: 1px solid ${Colors.heliotrope};
  }
`

const Search = () => {
  const inputEl = React.useRef(null)

  const onIconMouseDown = React.useCallback((e) => {
    e.preventDefault()
  }, [])

  const onIconClick = React.useCallback((e) => {
    inputEl.current.focus()
  }, [])

  return (
    <InlineForm role="search" action="/search" method="get" autoComplete="off">
      <Input
        name="query"
        type="search"
        spellCheck="false"
        aria-label="Search"
        placeholder="Search..."
        ref={inputEl}
      />
      <InputIcon
        className="search-icon"
        src={MagnifyingGlass}
        alt="Search Icon"
        onMouseDown={onIconMouseDown}
        onClick={onIconClick}
      />
    </InlineForm>
  )
}


export default Search