import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import * as Colors from "../../atomics/colors"
import { resizableContainerStyles } from "../../styles/containers"
import RightPanel from "../common/RightPanel"

const FooterWrapper = styled.footer`
  background-color: ${Colors.greyNavBackground};
  height: 42px;
  width: 100%;
  clear: both;
  flex-shrink: 0;
`

const FooterContent = styled.div`
  ${resizableContainerStyles};

  display: flex;
  margin: 0 auto;
  padding: 0 12px;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  color: ${Colors.grey};
  font-size: 14px;
`

const Footer = ({ siteTitle }) => (
  <FooterWrapper>
    <FooterContent>
      <div>© All Rights Reserved</div>
      <RightPanel />
    </FooterContent>
  </FooterWrapper>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: "",
}

export default Footer
