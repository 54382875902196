import * as React from "react"
import styled from "styled-components"

import SidePanel from "../sidePanel/SidePanel"

const AsideContainer = styled.aside`
  @media only screen and (min-width: 800px) {
    margin-left: 100px;
    padding-top: 106px;
    width: 220px;
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
    margin-bottom: 58px;
  }
`

const Aside = (props) => {
  return (
    <AsideContainer>
      <SidePanel variant="primary" />
    </AsideContainer>
  )
}

export default Aside